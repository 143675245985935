import Cookies from 'js-cookie';
import { DateTime } from 'luxon';

import { COMMONS, DATE_FORMAT } from 'globalConstants';
import {
  FormatTimeStampType,
  GetTodayType,
  CompareDatesType,
  FormatDateTimeType,
  OperationInDateType,
  DatesDiffType,
  getFormattedDateFromIsoDateType,
} from 'types/dateHelpers';

const { YYYY_MM_DD, YYYY_MM_DD_HH_MM_A } = DATE_FORMAT;
const { ADD, SUBTRACT } = COMMONS;

// default time zone set to tenant's timezone
// export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';
export const DEFAULT_TIME_ZONE = Cookies.get('tenant-timezone');

export const GetToday: GetTodayType = (format = null, timeZone = DEFAULT_TIME_ZONE) => {
  if (timeZone) {
    if (format) {
      return DateTime.now().setZone(timeZone).toFormat(format);
    } else {
      return DateTime.now().setZone(timeZone);
    }
  } else {
    if (format) {
      return DateTime.now().toFormat(format);
    } else {
      return DateTime.now();
    }
  }
};

export const FormatTimeStamp: FormatTimeStampType = (
  timeStamp,
  format = YYYY_MM_DD,
  timeZone = DEFAULT_TIME_ZONE
) => {
  if (!timeStamp) {
    return '';
  }
  if (timeZone) {
    return DateTime.fromSeconds(timeStamp).setZone(timeZone).toFormat(format);
  } else {
    return DateTime.fromSeconds(timeStamp).toFormat(format);
  }
};

export const compareDates: CompareDatesType = (
  date1,
  date2,
  compBy = 'day',
  compType = 'EQUAL'
) => {
  switch (compType) {
    case 'EQUAL':
      return DateTime.fromISO(date2).startOf(compBy) == DateTime.fromISO(date1).startOf(compBy);
    case 'LESSTHAN':
      return DateTime.fromISO(date2).startOf(compBy) < DateTime.fromISO(date1).startOf(compBy);
    case 'GREATERTHAN':
      return DateTime.fromISO(date2).startOf(compBy) > DateTime.fromISO(date1).startOf(compBy);
    case 'LESSTHANEQUALTO':
      return DateTime.fromISO(date2).startOf(compBy) <= DateTime.fromISO(date1).startOf(compBy);
    case 'GREATERTHANEQUALTO':
      return DateTime.fromISO(date2).startOf(compBy) >= DateTime.fromISO(date1).startOf(compBy);
    default:
      return DateTime.fromISO(date2).startOf(compBy) == DateTime.fromISO(date1).startOf(compBy);
  }
};

export const FormatDateTime: FormatDateTimeType = (dateTime, format = YYYY_MM_DD, timeZone) => {
  if (!dateTime) {
    return '';
  }

  let tempDate: number;
  if (typeof dateTime === 'string') {
    const jsDate = new Date(dateTime).toISOString();
    tempDate = DateTime.fromISO(jsDate).toSeconds();
  } else {
    tempDate = DateTime.fromISO(dateTime.toISOString()).toSeconds();
  }

  if (timeZone) {
    return DateTime.fromSeconds(tempDate).setZone(timeZone).toFormat(format);
  } else {
    return DateTime.fromSeconds(tempDate).toFormat(format);
  }
};

export const OperationInDate: OperationInDateType = (
  dateTime,
  duration = 0,
  optBy = 'days',
  format = YYYY_MM_DD,
  optType = ADD
) => {
  const timeZone = DEFAULT_TIME_ZONE;
  if (!dateTime) {
    return '';
  }

  const tempDate =
    typeof dateTime === 'string'
      ? DateTime.fromISO(dateTime)
      : DateTime.fromISO(dateTime.toISOString());

  switch (optType) {
    case ADD:
      return timeZone
        ? tempDate
            .plus({ [optBy]: duration })
            .setZone(timeZone)
            .toFormat(format)
        : tempDate.plus({ [optBy]: duration }).toFormat(format);
    case SUBTRACT:
      return timeZone
        ? tempDate
            .minus({ [optBy]: duration })
            .setZone(timeZone)
            .toFormat(format)
        : tempDate.minus({ [optBy]: duration }).toFormat(format);
    default:
      return '';
  }
};

export const DatesDiff: DatesDiffType = (date1, date2, diffBy = 'days') => {
  const start =
    typeof date1 === 'string' ? DateTime.fromISO(date1) : DateTime.fromISO(date1.toISOString());
  const end =
    typeof date2 === 'string' ? DateTime.fromISO(date2) : DateTime.fromISO(date2.toISOString());
  const dateDiff = end.diff(start, diffBy);
  return dateDiff.toObject();
};

/**
 * @param {dateTime} Date | string | number
 * @returns {String} Short day format => "Fri, Jan 27"
 */
export const FormatShortDate = (
  dateTime: Date | string | number,
  format?: string,
  useTimezone?: boolean
): string => {
  const renderFormat = format || 'ccc, LLL d';
  let outputUnixTimestamp = 0;

  if (typeof dateTime === 'number') {
    outputUnixTimestamp = dateTime;
  }

  if (dateTime instanceof Date) {
    const inputDateTime = DateTime.fromJSDate(dateTime);
    outputUnixTimestamp = inputDateTime.toSeconds();
  }

  if (typeof dateTime === 'string') {
    outputUnixTimestamp = DateTime.fromISO(formatISODate(dateTime)).toSeconds();
  }

  if (useTimezone) {
    return DateTime.fromSeconds(outputUnixTimestamp)
      .setZone(DEFAULT_TIME_ZONE)
      .toFormat(renderFormat);
  } else {
    return DateTime.fromSeconds(outputUnixTimestamp).toFormat(renderFormat);
  }
};

export const GetStartWeekDate = (date = DateTime.now().toISODate()): Date => {
  const ISODate = DateTime.fromISO(formatISODate(date));
  return ISODate.startOf('week').toJSDate();
};

export const GetStartEndDayUnix = (
  date: Date,
  timeZone = DEFAULT_TIME_ZONE
): { startOfDayUnix: number; endOfDayUnix: number } => {
  const currentDate = DateTime.fromJSDate(date, { zone: timeZone });

  const startOfDay = currentDate.startOf('day');
  const startOfDayUnix = startOfDay.toSeconds();

  const endOfDay = currentDate.endOf('day');
  const endOfDayUnix = endOfDay.toSeconds();

  return {
    startOfDayUnix,
    endOfDayUnix,
  };
};

export const GetStartEndDayUnixFromFormat = (
  date: string,
  fromString: string,
  timeZone = DEFAULT_TIME_ZONE
): { startOfDayUnix: number; endOfDayUnix: number } => {
  const currentDate = DateTime.fromFormat(date, fromString, { zone: timeZone });

  const startOfDay = currentDate.startOf('day');
  const startOfDayUnix = startOfDay.toSeconds();

  const endOfDay = currentDate.endOf('day');
  const endOfDayUnix = endOfDay.toSeconds();

  return {
    startOfDayUnix,
    endOfDayUnix,
  };
};

export const formatTimeStamp = (timestamp: number, LocaleString = DateTime.DATE_MED): string =>
  DateTime.fromJSDate(new Date(timestamp * 1000))
    .setLocale('en')
    .toLocaleString(LocaleString);

// ISO standard extended format
export const formatISODate = (inputDateString: string) => {
  // Check if inputDateString is in 'MM/dd/yyyy' format
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputDateString)) {
    // Parse the input date string using the 'MM/dd/yyyy' format
    const inputDate = DateTime.fromFormat(inputDateString, 'MM/dd/yyyy');
    // Format the date using the 'yyyy-dd-MM' format
    const outputDateString = inputDate.toFormat('yyyy-dd-MM');
    return outputDateString; // "2023-15-02"
  }
  return inputDateString;
};

/**
 * Returns formatted date string in tenant timezone ( JS Date -> Date String )
 *
 * @param {Date} date The JS date instance
 * @param {string} format The return format
 * @return {string} formatted date string ( JS Date -> Date String timezoned )
 */
export const JSDateToFormattedStringWTz = (
  date: Date,
  format: string = YYYY_MM_DD,
  timeZone: string = DEFAULT_TIME_ZONE || ''
) => {
  return DateTime.fromJSDate(date).setZone(timeZone).toFormat(format);
};

/**
 * 
BELOW HELPERS DONOT MAKE USE OF TIMEZONE 
THEY ARE IRRESPECTIVE OF TIMEZONES AND ARE USED TO FORMAT DATETIME ON FRONTEND ONLY 
 */
/**
 * JS DATE HELPERS
 * JS DATE -> DATE STRING
 * JS DATE -> TIMESTAMP
 * JS DATE -> ISO FORMAT
 */

// JS DATE -> STRING ( date )
export const JSDateToFormattedString = (date: Date, format: string = YYYY_MM_DD) => {
  return DateTime.fromJSDate(date).toFormat(format);
};

// JS DATE -> ISO String
export const JSDateToISOString = (date: Date) => {
  return DateTime.fromJSDate(date).toISO();
};

// JS DATE -> TIMESTAMP
export const JSDateToTimeStamp = (date: Date) => {
  return Math.floor(DateTime.fromJSDate(date).toSeconds());
};

// dateString -> JS DATE INSTANCE
export const GetJSDateOfString = (dateString: string) => {
  return new Date(DateTime.fromISO(dateString).toISODate());
};

// STRING ( 2023-11-12 10:13 ) -> timestamp
export const GetTimeStampOfDateAsString = (
  dateAsString: string,
  format: string = YYYY_MM_DD_HH_MM_A
) => {
  return Math.floor(DateTime.fromFormat(`${dateAsString}`, format).valueOf() / 1000);
};

// string -> luxon datetime
export const GetDateTimeInstanceOfString = (dateTime: string) => {
  return DateTime.fromISO(dateTime);
};

// luxon date -> add no of days
export const AddNoOfDaysToDate = (dateTime: DateTime, days: number) => {
  return dateTime.plus({ day: days });
};

// luxon date -> add no of days
export const SubtractNoOfDaysToDate = (dateTime: DateTime, days: number) => {
  return dateTime.minus({ day: days });
};

// date string -> get timestamp of start of day string
export const GetStartOfDayInSeconds = (dateTime: string) => {
  return DateTime.fromISO(dateTime).startOf('day').toSeconds();
};

// date string -> get timestamp of end of day string
export const GetEndOfDayInSeconds = (dateTime: string) => {
  return DateTime.fromISO(dateTime).endOf('day').toSeconds();
};

// format simple
export const getFormattedStringOfDateTime = (dateTime: DateTime, format: string = YYYY_MM_DD) => {
  return dateTime.toFormat(format);
};

export const getDateTime = (format = YYYY_MM_DD) => {
  return DateTime.now().toFormat(format);
};

export const getFormattedDateFromIsoDate: getFormattedDateFromIsoDateType = (
  date: string,
  format: string,
  timeZone = DEFAULT_TIME_ZONE
) => {
  return DateTime.fromISO(date).setZone(timeZone).toFormat(format);
};

/**
 * tsToJSDateWithTimezonedProperties - timestamp to js date with timezoned properties
 * @param timeStamp - timestamp in seconds ( previously timezoned )
 * @param timeZone  - timezone string
 * @returns - new Date() instance with timezoned properties
 *
 */
export const tsToJSDateWithTimezonedProperties = (timeStamp: number, timeZone: string) => {
  const dateTimeInstance = DateTime.fromSeconds(timeStamp as number).setZone(timeZone);

  const year = dateTimeInstance.year;
  const month = dateTimeInstance.month - 1; // month is 0 indexed
  const day = dateTimeInstance.day;
  const hour = dateTimeInstance.hour;
  const minute = dateTimeInstance.minute;
  const second = dateTimeInstance.second;

  const newJSDate = new Date(year, month, day, hour, minute, second);
  return newJSDate;
};

/**
 *
 * @param dateInstance js date instance
 * @param timeZone time zone (required)
 * @returns  timestamp in seconds which is converted to time zone for selected date, hour, minute and seconds
 */
export const jsDateToTimeZonedTimeStamp = (dateInstance: Date, timeZone: string) => {
  const hour = dateInstance.getHours();
  const minute = dateInstance.getMinutes();
  const seconds = dateInstance.getSeconds();

  const timestamp = DateTime.fromISO(FormatDateTime(dateInstance, 'yyyy-MM-dd', null), {
    zone: timeZone,
  })
    .set({
      hour: hour,
      minute: minute,
      second: seconds,
      millisecond: 0,
    })
    .toSeconds();

  return timestamp;
};

/**
 * convertSecondsToTime
 * @param {string} secondsFromMidnight - seconds from midnight
 * example if it is 3600 then it is 1 hour from midnight ( 1:00 AM )
 * @returns {Date} - returns Date instance with hours and minutes set from seconds
 * Date is in Local timezone
 */

export const secondsFromMidNightToLocalDate = (secondsFromMidnight: string | null | undefined) => {
  const tempDate = new Date();
  tempDate.setHours(0);
  tempDate.setMinutes(0);

  if (!secondsFromMidnight) return null;
  if (!parseInt(secondsFromMidnight)) return null;
  const totalSeconds = parseInt(secondsFromMidnight, 10);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  tempDate.setHours(hours);
  tempDate.setMinutes(minutes);
  return tempDate;
};

/**
 * convertLocalDateTimeToSeconds
 * @param {Date} dateTime - Date instance
 * @returns {string} - returns seconds from midnight
 * example if it is 1:00 AM then it is 3600 seconds from midnight
 */
export const localDateToSecondsFromMidNight = (dateTime: Date | null | undefined) => {
  if (!dateTime) return null;

  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const totalSeconds = hours * 3600 + minutes * 60;
  return totalSeconds.toString();
};

/**
 *
 * @param day - day of the week
 * @returns - number of the day
 * example - sunday -> 0, monday -> 1, tuesday -> 2, wednesday -> 3, thursday -> 4, friday -> 5, saturday -> 6
 */
export const dayOfWeekToNumber = (day: string) => {
  if (!day) return -1;
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  if (!days.includes(day.toLowerCase())) return -1;
  return days.indexOf(day.toLowerCase());
};

/**
 *
 * @param timeStamp - timestamp in seconds
 * @param timeZone - time zone
 * @returns - JS Date instance
 */
export const timeStampToJSDate = (timeStamp: number, timeZone = DEFAULT_TIME_ZONE) => {
  return DateTime.fromSeconds(timeStamp).setZone(timeZone).toJSDate();
};

/**
 *
 * @param time - The time you want to check
 * @param startTime - The begining of the range
 * @param endTime - The end of the range
 * @returns - Boolean depending on whether or not the time falls in the range
 */
export const isInRange = (time: string, startTime: string, endTime: string): boolean => {
  // Parse the times using Luxon's DateTime object
  const timeDT = DateTime.fromFormat(time, 'HH:mm');
  const startDT = DateTime.fromFormat(startTime, 'HH:mm');
  const endDT = DateTime.fromFormat(endTime, 'HH:mm');

  // If start less than end, assume doesn't go over midnight
  if (startDT < endDT) {
    return timeDT >= startDT && timeDT <= endDT;
  } else {
    // Otherwise, assume goes over midnight
    return timeDT >= startDT || timeDT <= endDT;
  }
};

/**
 *
 * @param timeZone - time zone
 * @returns - timestamp of today in seconds
 */
export const GetTodayTimestamp = (timeZone = DEFAULT_TIME_ZONE) => {
  return DateTime.now().setZone(timeZone).toSeconds();
};
